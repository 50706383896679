















import { Component, Prop, Vue } from "vue-property-decorator";
import SharedNotificationListItem from "./ListItem.vue";

@Component({
    components: {
        SharedNotificationListItem,
    },
})
export default class SharedNotificationList extends Vue {
    @Prop() notifications!: any[];
}
