







































import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
        BaseAvatar,
    },
})
export default class SharedNotificationListItem extends Vue {
    @Prop() notification!: any;

    async goToRoute() {
        const isReaded = this.notification.is_readed;

        if (!isReaded)
            await this.$store.dispatch(
                "notifications/readNotification",
                this.notification.id
            );

        this.$router.push("/app" + this.notification.route);
    }
}
