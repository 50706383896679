










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MenuIcon extends Vue {
    @Prop({ default: 24 }) size!: string | number;
    @Prop({ default: "grey" }) color!: string;
}
