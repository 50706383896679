
















































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import BaseAvatar from "../Base/BaseAvatar.vue";
import SharedNotificationList from "../Shared/Notification/List.vue";
import AppIcon from "./AppIcon.vue";
import MenuIcon from "./MenuIcon.vue";

@Component({
    components: {
        MenuIcon,
        AppIcon,
        BaseAvatar,
        SharedNotificationList,
    },
})
export default class NavigationBar extends Vue {
    @Prop({ default: "white" }) color!: string;

    get me() {
        return this.$store.state.users.me;
    }

    get isLocked() {
        const isLocked = this.me && !this.me.is_active;

        return isLocked;
    }

    get showBackButton() {
        let path = this.$route.path;
        let pathSplit = path.split("/");
        let showBackButton = pathSplit.length > 3;

        return showBackButton;
    }

    get notifications() {
        return this.$store.state.notifications.notifications;
    }

    get unreadedNotifications() {
        return this.$store.getters["notifications/unreadedNotifications"];
    }

    @Emit("menu:click")
    menuOnClick() {
        return null;
    }

    settingOnClick() {
        this.$router.push("/app/pengaturan");
    }

    signOut() {
        this.$store.dispatch("users/signOut");
    }

    goBack() {
        this.$store.commit("meta/SET_PAGE_TRANSITION", "back");
        this.$router.go(-1);
    }

    goHome() {
        this.$store.commit("meta/SET_PAGE_TRANSITION", "back");
        this.$router.push("/app");
    }
}
