

















import { Component, Vue } from "vue-property-decorator";
import NavigationDrawer from "@/components/Others/NavigationDrawer.vue";
import NavigationBar from "@/components/Others/NavigationBar.vue";
import NavigationBottom from "@/components/Others/NavigationBottom.vue";
import OthersNotificationList from "@/components/Others/NotificationList.vue";
import { toDisplayName } from "@/helpers/Formatter";

import { getPermission } from "@/services/Push";
getPermission();

@Component({
    components: {
        NavigationDrawer,
        NavigationBar,
        NavigationBottom,
        OthersNotificationList,
    },

    metaInfo() {
        const siteName = "Aplikasi";
        const template = " | eCSR";
        const me = this.$store.state.users.me;
        const title = toDisplayName(me);
        const description =
            "Mempermudah urusan CSR baik untuk perusahaan maupun organisasi/masyarakat.";

        return {
            title: title,
            titleTemplate: "%s" + template,
            meta: [
                {
                    name: "description",
                    content: description,
                },
                {
                    property: "og:title",
                    content: title,
                },
                { property: "og:site_name", content: siteName },
                { property: "og:type", content: "website" },
                { name: "robots", content: "index,follow" },
            ],
        };
    },
})
export default class LayoutApp extends Vue {
    public showDrawer: boolean | null = null;

    get me() {
        return this.$store.state.users.me;
    }

    get meRole() {
        return this.$store.getters["users/meRole"];
    }

    get isMobile() {
        return this.$vuetify.breakpoint.xs;
    }

    get navigations() {
        let navigations: any[] = [];

        if (this.meRole === "ADMIN") {
            navigations = [
                {
                    title: "Dashboard",
                    icon: "mdi-view-dashboard",
                    route: "/app",
                },
                {
                    title: "Proposal",
                    icon: "mdi-clipboard-list",
                    route: "/app/proposal",
                },
                {
                    title: "Rencana CSR",
                    icon: "mdi-domain",
                    route: "/app/rencana",
                },
                {
                    title: "User",
                    icon: "mdi-account-group",
                    route: "/app/user",
                },
                {
                    title: "Setup",
                    icon: "mdi-cog",
                    route: "/app/setup",
                },
            ];
        }

        if (this.meRole === "COMPANY") {
            navigations = [
                {
                    title: "Dashboard",
                    icon: "mdi-view-dashboard",
                    route: "/app",
                },
                {
                    title: "Proposal",
                    icon: "mdi-clipboard-list",
                    route: "/app/proposal",
                },
                {
                    title: "Rencana CSR",
                    icon: "mdi-domain",
                    route: "/app/rencana",
                },
                {
                    title: "Galeri",
                    icon: "mdi-folder-image",
                    route: "/app/galeri",
                },
            ];
        }

        if (this.meRole === "PROPOSER") {
            navigations = [
                {
                    title: "Dashboard",
                    icon: "mdi-view-dashboard",
                    route: "/app",
                },
                {
                    title: "Proposal",
                    icon: "mdi-clipboard-list",
                    route: "/app/proposal",
                },
                {
                    title: "Galeri",
                    icon: "mdi-folder-image",
                    route: "/app/galeri",
                },
            ];
        }

        return navigations;
    }

    afterEnter() {
        window.scrollTo(0, 0);
    }

    afterLeave() {
        this.$store.commit("meta/SET_PAGE_TRANSITION", "default");
    }

    get pageTransition() {
        let transition: any = {
            name: "fade",
            mode: "out-in",
        };

        if (this.isMobile) {
            transition = {
                name: this.$store.state.meta.pageTransition.name,
                mode: this.$store.state.meta.pageTransition.mode,
            };
        }

        return transition;
    }

    async mounted() {
        await this.$store.dispatch("categories/getAllCategories");
        await this.$store.dispatch("types/getAllTypes");
    }
}
