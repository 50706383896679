













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppIcon extends Vue {
    @Prop({ default: "drawer lighten-1" }) color!: string;
    @Prop({ default: 40 }) size!: number | string;
    @Prop({ default: 24 }) imageSize!: number | string;
    @Prop({ default: require("@/assets/logo.png") }) src!: any;
}
