
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NavigationBottom extends Vue {
    @Prop() navigations!: any[];
}
