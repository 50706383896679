





















































import { Component, Prop, Vue } from "vue-property-decorator";
import MenuIcon from "./MenuIcon.vue";
import AppIcon from "./AppIcon.vue";

@Component({
    components: {
        MenuIcon,
        AppIcon,
    },
})
export default class NavigationDrawer extends Vue {
    @Prop() value!: boolean;
    @Prop() navigations!: any[];

    get showDrawer() {
        return this.value;
    }

    set showDrawer(newValue: boolean) {
        this.$emit("input", newValue);
    }
}
