var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"shadow",staticStyle:{"z-index":"100 !important"},attrs:{"app":"","color":_vm.color,"height":_vm.$isMobile ? '' : '75'}},[(_vm.$isMobile)?_c('v-layout',[(_vm.showBackButton)?_c('v-btn',{staticClass:"button",attrs:{"icon":"","height":"40","width":"40"},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"size":"20"},domProps:{"textContent":_vm._s('mdi-arrow-left')}})],1):_c('app-icon',{attrs:{"color":"grey lighten-4"},on:{"click":_vm.goHome}})],1):_c('v-btn',{staticClass:"ml-n2 ml-md-0",attrs:{"icon":"","height":"40","width":"40"},on:{"click":_vm.menuOnClick}},[_c('menu-icon',{staticClass:"mt-1",attrs:{"size":20,"color":"grey"}})],1),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","offset-y":"","nudge-left":_vm.$isMobile ? 0 : 191,"min-width":"240px","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"icon":"","height":"40","width":"40"}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"content":_vm.unreadedNotifications.length,"value":_vm.unreadedNotifications.length,"color":"green","overlap":"","bordered":""}},[_c('v-icon',{attrs:{"size":"20","color":"black"},domProps:{"textContent":_vm._s('mdi-bell')}})],1)],1)]}}])},[_c('v-card-text',{staticClass:"pa-0",staticStyle:{"max-height":"50vh"}},[_c('shared-notification-list',{staticClass:"white",attrs:{"notifications":_vm.notifications}}),_c('div',{staticClass:"pa-4 white"},[_c('v-btn',{staticClass:"info lighten-4",attrs:{"block":"","depressed":""},on:{"click":function($event){return _vm.$router.push('/app/notifikasi')}}},[_c('span',{staticClass:"\n                                text-none\n                                primary--text\n                                font-weight-medium\n                            ",domProps:{"textContent":_vm._s('Lihat semua notifikasi')}})])],1)],1)],1),_c('v-menu',{attrs:{"bottom":"","offset-y":"","nudge-left":_vm.$isMobile ? 0 : 191,"min-width":"240px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-layout',{staticClass:"justify-end align-center"},[_c('v-sheet',{staticClass:"px-3 ml-3 rounded-lg",attrs:{"color":"grey lighten-3"}},[(_vm.me)?_c('span',{staticClass:"overline",domProps:{"textContent":_vm._s(_vm.me.role.name)}}):_vm._e()]),_c('base-avatar',{staticClass:"mr-0 mr-sm-2 ml-2",attrs:{"user":_vm.me}})],1)],1)]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"py-2",on:{"click":_vm.settingOnClick}},[_c('v-layout',{staticClass:"align-center"},[_c('v-btn',{staticClass:"button",staticStyle:{"pointer-events":"none"},attrs:{"icon":"","height":"40","width":"40"}},[_c('v-icon',{attrs:{"size":"20"},domProps:{"textContent":_vm._s('mdi-account-cog')}})],1),_c('span',{staticClass:"ml-4",domProps:{"textContent":_vm._s('Pengaturan')}})],1)],1),_c('v-list-item',{staticClass:"py-2",on:{"click":_vm.signOut}},[_c('v-layout',{staticClass:"align-center"},[_c('v-btn',{staticClass:"button",staticStyle:{"pointer-events":"none"},attrs:{"icon":"","height":"40","width":"40"}},[_c('v-icon',{attrs:{"size":"20"},domProps:{"textContent":_vm._s('mdi-logout-variant')}})],1),_c('span',{staticClass:"ml-4",domProps:{"textContent":_vm._s('Keluar')}})],1)],1)],1)],1),(_vm.isLocked)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"error lighten-5",attrs:{"icon":"","height":"40","width":"40"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","size":"20"},domProps:{"textContent":_vm._s('mdi-lock')}})],1)]}}],null,false,2953471843)},[_c('span',{domProps:{"innerHTML":_vm._s(
                    ("Akun anda dikunci karena <strong>" + (_vm.me.lock_reason) + "</strong>")
                )}})]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }